import React, {Component} from 'react';
import {Link} from 'gatsby';
import './NotFound.scss';
import ContactForm from './ContactForm';

export default class NotFound extends Component {
  render() {
    return (
      <div className="not-found">
        <div className="banner"/>
        <div className="container">
          <h1>Not found</h1>
          <p>
            Oh no it looks like that page doesn’t exist. Either the page was recently deleted or renamed, or 
            he URL was misspelled. Try going back or go to our homepage to find what you’re 
            looking for.
          </p>
          <Link className="btn btn-secondary" to={'/'}>home</Link>
        </div>
        <ContactForm/>
      </div>
    )
  }
}
